import create from 'zustand';

export interface ReadOnlyProps {
  isReadOnly: boolean;
  setReadOnly: (value: boolean) => void;
}

export const useReadOnly = create<ReadOnlyProps>((set) => ({
  isReadOnly: false,
  setReadOnly: (value) => set((state) => ({ isReadOnly: value })),
}));
