import { SvgIcon, SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import React from 'react';

const RemoveFilterIcon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> = (
  props: any
) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props}>
      <path d="M21 8H3V6H21V8ZM13.81 16H10V18H13.09C13.21 17.28 13.46 16.61 13.81 16ZM18 11H6V13H18V11ZM21.12 15.46L19 17.59L16.88 15.46L15.47 16.88L17.59 19L15.47 21.12L16.88 22.54L19 20.41L21.12 22.54L22.54 21.12L20.41 19L22.54 16.88L21.12 15.46Z" />
    </SvgIcon>
  );
};

export default RemoveFilterIcon;
