import deburr from 'lodash/deburr';
import isArray from 'lodash/isArray';
import lowerCase from 'lodash/lowerCase';
import orderBy from 'lodash/orderBy';
import { BestMatch, findBestMatch, Rating } from 'string-similarity';

interface scoredSearchProps<T> {
  originalArr: any[];
  strSearch: string;
  fieldSearch: string | string[];
  qtdRetItem?: number;
  minRate?: number;
}

export default function scoredSearch<T>({
  originalArr,
  strSearch,
  fieldSearch,
  qtdRetItem = 4,
  minRate = 0.5,
}: scoredSearchProps<T>): T[] {
  if (originalArr.length === 0 || strSearch === '') {
    return originalArr;
  }

  const cleanString = (str: string): string => {
    return lowerCase(deburr(str));
  };

  const fields = isArray(fieldSearch) ? fieldSearch : [fieldSearch];

  let ret = originalArr.filter((f) =>
    fields.reduce<boolean>(
      (bool, field) =>
        cleanString(f[field] as string).indexOf(cleanString(strSearch)) > -1 ||
        bool,
      false
    )
  );

  if (ret.length > 0) {
    return ret;
  }

  ret = [];

  const bms = fields.reduce<BestMatch[]>((arr, field) => {
    const arrOpt = originalArr.flatMap((item) => {
      if (
        !item[field] ||
        (isArray(item[field]) && (item[field] as string[]).length === 0)
      ) {
        return [''];
      }

      if (isArray(item[field])) return item[field] as string[];

      return [item[field]] as string[];
    }) as string[];
    return [...arr, findBestMatch(strSearch, arrOpt)];
  }, []);

  const ratings = bms.reduce<Rating[]>(
    (arr, bm) => [...arr, ...bm.ratings],
    []
  );
  const sortedBm = orderBy(ratings, ['rating'], ['desc']);

  for (let i = 0; i < qtdRetItem; i++) {
    if (sortedBm[i]) {
      const it = originalArr.filter((f) =>
        fields.reduce<boolean>(
          (bool, field) =>
            (f[field] === sortedBm[i].target && sortedBm[i].rating > minRate) ||
            bool,
          false
        )
      );
      ret.push(...it);
    }
  }

  return ret;
}
