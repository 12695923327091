import { Box, Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import BoredCat from '../../../modules/assets/bored-cat';
import RemoveFilterIcon from '../../../modules/assets/RemoveFilterIcon';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '60vh',
    gap: 24,
  },

  button: {
    borderRadius: 18,
  },
}));

interface NoItemsFoundProps {
  text: string;
  resetFilterValues: () => void;
}

const NoItemsFound: React.FC<NoItemsFoundProps> = ({
  text,
  resetFilterValues,
}) => {
  const classes = useStyles();

  return (
    <Box data-testid="no-items" className={classes.root}>
      <BoredCat bgColor="#cacecc" />

      <Typography
        style={{ color: '#313533', fontSize: '1.125rem', textAlign: 'center' }}
      >
        {text}
      </Typography>

      <Button
        variant="outlined"
        color="primary"
        startIcon={<RemoveFilterIcon />}
        className={classes.button}
        onClick={resetFilterValues}
      >
        remover filtros
      </Button>
    </Box>
  );
};

export default NoItemsFound;
